export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [];

export const dictionary = {
		"/": [~5],
		"/authenticate": [~6,[2]],
		"/authenticate/[authType]": [~7,[2]],
		"/error/activation/other": [8],
		"/how-to": [~9],
		"/my-account": [~10,[3]],
		"/privacy": [~11],
		"/purchase": [~12,[4]],
		"/purchase/confirm": [~13,[4]],
		"/terms": [~14]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';